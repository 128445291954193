const COUNTRY_CONFIG = {
  countryList: ['hk', 'id', 'jp', 'kr', 'mo', 'my', 'sg', 'th', 'tw', 'vn'],
  singlePhotoEnabledCountries:
    process.env.REACT_APP_SINGLE_PHOTO_OBT_SUPPORTED_COUNTRY.split(/[, ]+/g),
  photoSubmissionDisabledCountries:
    process.env.REACT_APP_PHOTO_SUBMISSION_FLOW_DISABLED_COUNTRY.split(/[, ]+/g),
  teensOBTEnabledCountries:
    process.env.REACT_APP_TEENS_OBT_SUPPORTED_COUNTRY.split(/[, ]+/g),
  directSlotBookingCountries: (
    process.env.REACT_APP_DIRECT_SLOT_BOOKING_COUNTRIES || ''
  ).split(/[, ]+/g),
  webFlowEnabledCountries: (
    process.env.REACT_APP_WEB_FLOW_SUPPORTED_COUNTRY || ''
  ).split(/[, ]+/g)
};

export default COUNTRY_CONFIG;
