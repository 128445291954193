import sha256 from 'crypto-js/sha256';
import enc from 'crypto-js';
import axios from 'axios';
import { store } from './store';
import * as actions from './actions/appActions';
import { COUNTRY_CONFIG, OBT_CONFIG } from './configs';
import { CountryName } from './constants';
import LocalStorage from './services/storage';

export const getLocale = (country) => {
  switch (country) {
    case 'hk':
      return 'zh';
    case 'id':
      return 'id';
    case 'tw':
      return 'zh';
    case 'th':
      return 'th';
    case 'vn':
      return 'vi';
    case 'mo':
      return 'zh';
    case 'jp':
      return 'ja';
    case 'kr':
      return 'ko';
    default:
      return 'en';
  }
};

export const validateEmail = (email) => {
  const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
  return (regex.test(email) && !(/(\.con)$/).test(email));
};

export const isValidPhoneNumber = (number) => {
  const regex = /^\d+$/;
  return (regex.test(number));
};

export const isEmpty = (value) => {
  if (!value || (typeof value === 'string' && value?.trim() === '') || value === null || (value && value.length === 0) || (Object.keys(value)?.length === 0)) {
    return true;
  }
  return false;
};

export const showNotification = (msg, type, title = null, sleepTime = 3000, callback) => {
  store.dispatch({
    type: actions.APP_NOTIFIER_NOTIFICATION_SHOW,
    notification: { title, message: msg, type, sleepTime, callback }
  });
};

export const pushGTMUserData = (data) => {
  const gtmId = process.env.REACT_APP_GTM_ID;
  if (!gtmId) return;
  window.dataLayer.push({ ...data });
  // eslint-disable-next-line no-console
  console.log(`GTM data of user logged`);
};

export const pushGTMData = (trackLabel) => {
  const gtmId = process.env.REACT_APP_GTM_ID;
  if (!gtmId) return;
  window.dataLayer.push({
    GFTrackCategory: 'form',
    GFTrackAction: 'submission',
    GFTrackLabel: trackLabel,
    event: 'GFTrackEvent'
  });
  // eslint-disable-next-line no-console
  console.log(`GTM message send successfully with label ${trackLabel}`);
};

export const pushGTMPageViewData = (route) => {
  // const gtmId = process.env.REACT_APP_GTM_ID;
  // if (!gtmId) return;
  // window.dataLayer.push({
  //   event: 'pageview',
  //   page: {
  //     path: route,
  //     title: route,
  //     location: window.location.href
  //   }
  // });
  // eslint-disable-next-line no-console
  console.log(`Page view logged`);
};

export const getQueryParams = (utmData) => {
  let searchQuery = '?';
  Object.entries(utmData).forEach(([key, value]) => {
    searchQuery = value ? `${searchQuery}${key}=${value}&` : searchQuery;
  });
  return searchQuery.slice(0, -1);
};
export const checkIfMobileDevice = () => {
  let isMobile = false;
  if (navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) isMobile = true;
  return isMobile;
};

export const checkIfItemExists = (array, item) => array.includes(item);

export const validateName = (name) => {
  const regex = /[!@#$%^&*_+=\[\]{};:\\|.<>]+/g;
  return !(regex.test(name));
};

export const setDeviceCountry = async () => {
  let deviceCountry = LocalStorage.getStorage('device_country');
  if (!deviceCountry) {
    const response = await axios('https://ipapi.co/json/');
    const { data } = response;
    deviceCountry = data.country_name;
    LocalStorage.setStorage('device_country', deviceCountry);
  }
  return deviceCountry;
};

const obtVersion = process.env.REACT_APP_OBT_VERSION;

export const segmentPageCall = async ({ title, details, countryCode, languageCode }) => {
  const formId = LocalStorage.getStorage('form_id');
  let deviceCountry = LocalStorage.getStorage('device_country');
  if (!deviceCountry) { deviceCountry = await setDeviceCountry(); }
  const userId = JSON.parse(LocalStorage.getStorage('ajs_user_id'));
  if (!userId) return false;

  return (
    window.analytics.page(title, {
      ...details,
      country: CountryName[countryCode],
      OBT_form_id: formId || '',
      obt_version: obtVersion,
      obt_view: checkIfMobileDevice() ? 'mobile' : 'desktop',
      obt_platform: 'web',
      obt_device_country: deviceCountry,
      obt_url_country: CountryName[countryCode],
      path: `${countryCode}-${languageCode}`.toUpperCase(),
      type: 'page'
    }));
};

export const segmentEventTrack = ({ title, details, countryCode, languageCode }) => {
  const formId = LocalStorage.getStorage('form_id');
  const deviceCountry = LocalStorage.getStorage('device_country');
  const userId = JSON.parse(LocalStorage.getStorage('ajs_user_id'));
  if (!userId) return false;

  return (
    window.analytics.track(title, {
      ...details,
      country: CountryName[countryCode],
      OBT_form_id: formId || '',
      obt_version: obtVersion,
      obt_view: checkIfMobileDevice() ? 'mobile' : 'desktop',
      obt_platform: 'web',
      obt_device_country: deviceCountry,
      obt_url_country: CountryName[countryCode],
      path: `${countryCode}-${languageCode}`.toUpperCase()
    }));
};

export const segmentIdentifyCall = (details) => (
  window.analytics.identify(sha256(details.email).toString(enc.Hex), { ...details })
);

export const segmentEvents = ({ title, trackEvent, details, countryCode }) => {
  const formId = LocalStorage.getStorage('form_id');
  return (
    !trackEvent ? window.analytics.identify(sha256(details.email).toString(enc.Hex), { ...details, obt_view: checkIfMobileDevice() ? 'mobile' : 'desktop' })
      : (formId && window.analytics.track(title, {
        ...details,
        obt_form_id: formId,
        obt_version: obtVersion,
        obt_from: 'web',
        obt_view: checkIfMobileDevice() ? 'mobile' : 'desktop'
      },
      {
        context: {
          location: {
            country: CountryName[countryCode]
          }
        }
      })));
};

export const isMobileBrowser = () => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];
  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

export const labelRename = (obj, newKeys) => {
  const entries = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;

    return { [newKey]: obj[key] };
  });

  return Object.assign({}, ...entries);
};

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const getIsWebFlowEnabled = (countryCode) => OBT_CONFIG.webFlowEnabledOBT.includes(obtVersion)
  && COUNTRY_CONFIG.webFlowEnabledCountries.includes(countryCode);
