const OBT_CONFIG = {
  multivariateEnabledOBT:
    process.env.REACT_APP_MULTIVARIATE_TESTING_SUPPORTED_OBT.split(/[, ]+/g),
  obt4TestUIEnabledOBT: ['OBT4'],
  photoSubmissionDisabledOBT:
    process.env.REACT_APP_PHOTO_SUBMISSION_FLOW_DISABLED_OBT.split(/[, ]+/g),
  singlePhotoEnabledOBT:
    process.env.REACT_APP_SINGLE_PHOTO_OBT_SUPPORTED_OBT.split(/[, ]+/g),
  teensOBTEnabledOBT:
    process.env.REACT_APP_TEENS_OBT_SUPPORTED_OBT.split(/[, ]+/g),
  webFlowEnabledOBT: (
    process.env.REACT_APP_WEB_FLOW_ENABLED_OBT || ''
  ).split(/[, ]+/g)
};

export default OBT_CONFIG;
