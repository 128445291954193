import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import './styles.scss';

const Textarea = (props) => {
  const { formatMessage } = props.intl;
  const {
    className,
    label,
    onBlur,
    onChange,
    placeholder,
    type,
    value,
    maxLength
  } = props;

  return (
    <textarea
      label={label}
      placeholder={formatMessage(placeholder.props)}
      className={`custom-textarea text-area-${type} ${value ? 'filled-text-area' : null} ${className}`}
      type={type}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      maxLength={maxLength}
    />
  );
};

Textarea.propTypes = {
  className: PropTypes.string,
  formatMessage: PropTypes.func,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.objectOf(PropTypes.any),
  type: PropTypes.string,
  value: PropTypes.string,
  maxLength: PropTypes.number
};

Textarea.defaultProps = {
  className: '',
  formatMessage: () => {},
  label: '',
  onBlur: () => {},
  onChange: () => {},
  placeholder: '',
  type: 'text',
  value: '',
  maxLength: 5000
};

export default injectIntl(Textarea);
