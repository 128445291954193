import React from 'react';

import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './styles.scss';

const PhoneInputComponent = (props) => {
  const {
    value,
    country,
    handlePhoneNumberChange,
    placeholder,
    intl,
    preferredCountries,
    onBlur,
    name
  } = props;
  const { formatMessage } = intl;

  return (
    <PhoneInput
      country={country}
      value={value}
      placeholder={formatMessage(placeholder.props)}
      onChange={(phone, data) => handlePhoneNumberChange(phone, data)}
      preferredCountries={preferredCountries}
      onBlur={onBlur}
      countryCodeEditable={false}
      enableLongNumbers={country === 'jp'}
      inputProps={{ name }}
    />
  );
};

PhoneInputComponent.propTypes = {
  country: PropTypes.string,
  formatMessage: PropTypes.func,
  handlePhoneNumberChange: PropTypes.func,
  intl: PropTypes.objectOf(PropTypes.any).isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.objectOf(PropTypes.any),
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.string,
  name: PropTypes.string
};

PhoneInputComponent.defaultProps = {
  country: 'sg',
  formatMessage: () => {},
  handlePhoneNumberChange: () => {},
  onBlur: () => {},
  placeholder: '',
  preferredCountries: [],
  value: 'Mobile',
  name: ''
};

export default injectIntl(PhoneInputComponent);
