/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';


import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { COUNTRY_CONFIG, OBT_CONFIG, VERSION_CONFIG } from './configs';
import { launchScreenTranslations } from './translation/commonTranslations';
import fetchTranslation from './translation/sagas';
import { store } from './store';
import { getQueryParams, setDeviceCountry } from './utils';
import * as actions from './actions';
import storage from './services/storage';

const obtVersion = process.env.REACT_APP_OBT_VERSION;

const AllSetPage = React.lazy(() => import('./pages/all-set-page'));
const HelloPage = React.lazy(() => import('./pages/hello-page'));
const IntroPage = React.lazy(() => import('./pages/intro-page'));
const OnboardingPage = React.lazy(() => import('./pages/onboarding-page'));
const LaunchPage = React.lazy(() => import('./pages/launch-page'));
const LaunchLinePage = React.lazy(() => import('./pages/launch-line-page'));
const CameraPage = React.lazy(() => import('./pages/camera-page'));
const CameraD2MPage = React.lazy(() => import('./pages/cameraD2M-page'));
const PhotoErrorPage = React.lazy(() => import('./pages/photo-error-page'));
const URLNotFoundPage = React.lazy(() => import('./pages/url-not-found-page'));
const RemindMePage = React.lazy(() => import('./pages/remind-me-page'));
const SmileQuestions = React.lazy(() => import('./pages/smile-questions'));
const AgeThanksScreen = React.lazy(() => import('./pages/age-thanks-page'));
const SecondaryHelloPage = React.lazy(() => import('./pages/secondary-hello-page'));
const CompetitionPage = React.lazy(() => import('./pages/competition-page'));
const LineSuccessPage = React.lazy(() => import('./pages/line-success-page'));
const CarousalIntroPage = React.lazy(() => import('./pages/carousal-intro-page'));
const HelloFormPage = React.lazy(() => import('./pages/hello-form-page'));
const SecondarySmileQuestionsPage = React.lazy(() => import('./pages/secondary-smile-questions-page'));
const CameraPreviewPage = React.lazy(() => import('./pages/camera-preview-page'));
const ThankyouPage = React.lazy(() => import('./pages/thankyou-page'));
const FinalPage = React.lazy(() => import('./pages/finalScreen'));
const PhotoCapturePage = React.lazy(() => import('./pages/photo-capture-page'));
const PhotoCaptureD2MPage = React.lazy(() => import('./pages/photo-captureD2M-page'));
const PhotoResultPage = React.lazy(() => import('./pages/photo-result-page'));
const CaseAcceptancePage = React.lazy(() => import('./pages/case-acceptance'));
const BookAppointmetPage = React.lazy(() => import('./pages/book-appointment-screen'));
const ClinicSelectPage = React.lazy(() => import('./pages/clinic-select-page'));
const ResidenceErrorPage = React.lazy(() => import('./pages/residence-error-page'));
const DisclaimerPage = React.lazy(() => import('./pages/disclaimer-page'));
// const FreeAssessmentPage = React.lazy(() => import('./pages/initial-page'));
// const SmileQuestionQ1 = React.lazy(() => import('./pages/smile-question-Q1-page'));
// const SmileQuestionQ2 = React.lazy(() => import('./pages/smile-question-Q2-page'));
// const SmileQuestionQ3 = React.lazy(() => import('./pages/smile-question-Q3-page'));
// const CameraGuidLines = React.lazy(() => import('./pages/camera-guideline-page'));
// const SmileCapturePage = React.lazy(() => import('./pages/smile-capture-page'));
// const CameraErrorPage = React.lazy(() => import('./pages/camera-error-page'));
// const UserFormPage = React.lazy(() => import('./pages/user-form-page'));
// const ThanksPage = React.lazy(() => import('./pages/thanks-page'));
const AIthanksPage = React.lazy(() => import('./pages/ai-thanks-page'));

const Routes = (props) => {
  const match = useRouteMatch();
  const { formatMessage } = props.intl;
  const params = match.url.slice(1).split('-');
  const countryCode = params[0];
  const languageCode = params[1];

  useEffect(() => {
    fetchTranslation(countryCode, languageCode);
    setDeviceCountry();
  }, []);

  // persist query parameters on routing
  // useEffect(() => props.history.listen(() => {
  //   const { utmData } = props;
  //   if (utmData && Object.keys(utmData).length > 0 && !props.history.location.search) {
  //     const searchQuery = getQueryParams(utmData);
  //     props.history.push({
  //       pathname: `${props.history.location.pathname}/`,
  //       search: ''
  //     });
  //   }
  // }), [props.utmData]);

  useEffect(() => {
    store.dispatch({
      type: actions.APP_METADATA_SET,
      metaData: {
        countryCode,
        languageCode
      }
    });
  }, [match]);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const tags = query.get('tags');
    const email = query.get('email');
    const teensOBT = JSON.parse(query.get('teensOBT'));
    const id = query.get('id');
    const page = query.get('page');
    const referral = query.get('referral_id');
    const gclid = query.get('gclid');
    const couponCode = query.get('coupon_code');
    const lineUserId = query.get('lineuid');
    const encodedLiffUTMParams = query.get('liff.state');
    if (encodedLiffUTMParams) {
      const decodedLiffUTMParams = decodeURIComponent(encodedLiffUTMParams);
      const queryParams = new URLSearchParams(decodedLiffUTMParams);
      if (queryParams.get('email')) setEmail(queryParams.get('email'));
      const utmData = {
        utm_source: queryParams.get('utm_source') && queryParams.get('utm_source'),
        utm_term: queryParams.get('utm_term') && queryParams.get('utm_term'),
        utm_medium: queryParams.get('utm_medium') && queryParams.get('utm_medium'),
        utm_campaign: queryParams.get('utm_campaign') && queryParams.get('utm_campaign'),
        utm_content: queryParams.get('utm_content') && queryParams.get('utm_content')
      };
      if (utmData.utm_source || utmData.utm_source_opp
        || utmData.utm_term || utmData.utm_term_opp
        || utmData.utm_medium || utmData.utm_medium_opp
        || utmData.utm_campaign || utmData.utm_campaign_opp
        || utmData.utm_content || utmData.utm_content_opp) {
        saveUtmData(utmData);
        if (utmData.utm_source === 'youtube') {
          setSource(utmData.utm_source);
        }
      }
      if (queryParams.get('gclid')) setGoogleId(queryParams.get('gclid'));
      const facebookAdData = {
        utmId: query.get('utm_id'),
        adFbId: query.get('ad_fb_id'),
        fbclid: query.get('fbclid')
      };
      if (facebookAdData.utmId || facebookAdData.adFbId || facebookAdData.fbclid) {
        saveFacebookAdData(facebookAdData);
      }
    }
    if (tags) setTags(tags);
    if (email) {
      if (teensOBT) {
        setEmailTeens(email);
      } else {
        setEmail(email);
      }
    }
    if (id && id !== 'undefined') setUserId(id);
    if (referral && referral !== 'undefined') setReferralText(referral);
    if (gclid) setGoogleId(gclid);
    if (couponCode) setCouponCode(couponCode);
    if (lineUserId) setUserLineId(lineUserId);
    if (page) redirect(page);
    const utmData = {
      utm_source: query.get('utm_source') && query.get('utm_source'),
      utm_term: query.get('utm_term') && query.get('utm_term'),
      utm_medium: query.get('utm_medium') && query.get('utm_medium'),
      utm_campaign: query.get('utm_campaign') && query.get('utm_campaign'),
      utm_content: query.get('utm_content') && query.get('utm_content')
    };
    if (utmData.utm_source || utmData.utm_source_opp
      || utmData.utm_term || utmData.utm_term_opp
      || utmData.utm_medium || utmData.utm_medium_opp
      || utmData.utm_campaign || utmData.utm_campaign_opp
      || utmData.utm_content || utmData.utm_content_opp) {
      saveUtmData(utmData);
      if (utmData.utm_source === 'youtube') {
        setSource(utmData.utm_source);
      }
    }

    const facebookAdData = {
      utmId: query.get('utm_id'),
      adFbId: query.get('ad_fb_id'),
      fbclid: query.get('fbclid')
    };

    if (facebookAdData.utmId || facebookAdData.adFbId || facebookAdData.fbclid) {
      saveFacebookAdData(facebookAdData);
    }

    const singlePhotoParam = query.get('isSinglePhotoOBT');
    const metaDataOBT = JSON.parse(storage.getStorage('obt-zenyum-metaData'));
    const localSinglePhotoObtEnabled = metaDataOBT?.isSinglePhotoOBT;

    if (['true', 'false'].includes(singlePhotoParam)) {
      updateSinglePhotoOBT(singlePhotoParam === 'true');
    } else if (typeof localSinglePhotoObtEnabled !== 'boolean') {
      const cameraVersion = JSON.parse(storage.getStorage('obt-zenyum-form3'))?.version;
      const isMultivariateEligible = OBT_CONFIG.multivariateEnabledOBT.includes(obtVersion);
      const isCameraVersionSupported = VERSION_CONFIG.singlePhotoEnabledCameraVersion.includes(String(cameraVersion));

      const isSinglePhotoOBTEnabledByDefault = ((!isMultivariateEligible || isCameraVersionSupported)
      && OBT_CONFIG.singlePhotoEnabledOBT.includes(obtVersion)
      && COUNTRY_CONFIG.singlePhotoEnabledCountries.includes(countryCode));
      updateSinglePhotoOBT(isSinglePhotoOBTEnabledByDefault);
    }
  }, [props.location.search]);

  const saveUtmData = (utmData) => {
    store.dispatch({ type: actions.SAVE_UTM_DATA, data: utmData });
  };

  const setTags = (tags) => store.dispatch({
    type: actions.SAVE_TAGS_DATA,
    tags
  });

  const setEmail = (email) => store.dispatch({
    type: actions.APP_EMAIL_SET,
    email
  });

  const setEmailTeens = (email) => store.dispatch({
    type: actions.APP_EMAIL_TEENS_SET,
    email
  });

  const setUserId = (id) => store.dispatch({
    type: actions.APP_USER_ID_SET,
    id
  });

  const setReferralText = (text) => store.dispatch({
    type: actions.SAVE_REFERRAL_DATA,
    text
  });

  const setGoogleId = (gclid) => store.dispatch({
    type: actions.SAVE_GOOGLE_ID,
    gclid
  });

  const setCouponCode = (couponCode) => store.dispatch({
    type: actions.SAVE_COUPON_CODE,
    couponCode
  });

  const setUserLineId = (lineUserId) => store.dispatch({
    type: actions.SAVE_LINE_DATA,
    data: { userId: lineUserId }
  });

  const setSource = (srcOrigin) => store.dispatch({
    type: actions.SET_SOURCE_OBT,
    srcOrigin
  });

  const saveFacebookAdData = (facebookAdData) => store.dispatch({
    type: actions.SAVE_FACEBOOK_AD_DATA,
    facebookAdData
  });

  const updateSinglePhotoOBT = (isSinglePhotoOBT) => store.dispatch({
    type: actions.UPDATE_SINGLE_PHOTO_OBT_VALUE,
    data: isSinglePhotoOBT
  });

  const redirect = (page) => {
    if (page) return props.history.push(`${match.url}${page}`);
    return props.history.push(`${match.url}hello-screen`);
  };

  const defaultRedirectionURL = match.url.charAt(match.url.length - 1) === '/'
    ? `${match.url}launch${window.location.search}` : `${match.url}/launch${window.location.search}`;

  const metaData = JSON.parse(storage.getStorage('obt-zenyum-form1'));

  useEffect(() => {
    if (metaData) {
      window.emailAddress = metaData.email;
      window.phoneNumber = metaData.phone && metaData.phone.number;
    }
  }, [metaData]);

  useEffect(() => {
    const teensOBT = !!(props.utmData?.['utm_campaign']?.includes('ParentsOfTeens'));
    storage.setStorage('teens_obt', teensOBT);
  }, [props.utmData]);

  return (
    <>
      <Helmet>
        <title>{`${formatMessage(((launchScreenTranslations.metaTitle)?.props))}`}</title>
        <meta name="description" content={`${formatMessage(((launchScreenTranslations.metaDescription)?.props))}`} />
      </Helmet>
      <Switch>
        <Redirect from={`${match.url}`} to={defaultRedirectionURL} exact={true} />
        {/* <Route path={`${match.url}/booth`} exact={true} component={FreeAssessmentPage} />
      <Route path={`${match.url}/smile-question-Q1`} exact={true} component={SmileQuestionQ1} />
      <Route path={`${match.url}/smile-question-Q2`} exact={true} component={SmileQuestionQ2} />
      <Route path={`${match.url}/smile-question-Q3`} exact={true} component={SmileQuestionQ3} />
      <Route path={`${match.url}/image-guidelines`} exact={true} component={CameraGuidLines} />
      <Route path={`${match.url}/smile-capture`} exact={true} component={SmileCapturePage} />
      <Route path={`${match.url}/camera-error`} exact={true} component={CameraErrorPage} />
      <Route path={`${match.url}/thanks`} exact={true} component={ThanksPage} />
      <Route path={`${match.url}/user-form`} exact={true} component={UserFormPage} /> */}
        <Route path={`${match.url}/all-set`} exact={true} component={AllSetPage} />
        <Route path={`${match.url}/camera`} exact={true} component={CameraPage} />
        <Route path={`${match.url}/cameraD2M`} exact={true} component={CameraD2MPage} />
        <Route path={`${match.url}/hello-screen`} exact={true} component={HelloPage} />
        <Route path={`${match.url}/hello-user`} exact={true} component={SecondaryHelloPage} />
        <Route path={`${match.url}/intro`} exact={true} component={IntroPage} />
        <Route path={`${match.url}/onboarding`} exact={true} component={OnboardingPage} />
        <Route path={`${match.url}/launch`} exact={true} component={LaunchPage} />
        <Route path={`${match.url}/line-launch`} exact={true} component={LaunchLinePage} />
        <Route path={`${match.url}/photo-error`} exact={true} component={PhotoErrorPage} />
        <Route path={`${match.url}/remindme/:age`} exact={true} component={RemindMePage} />
        <Route path={`${match.url}/remindme-thanks`} exact={true} component={AgeThanksScreen} />
        <Route path={`${match.url}/smile-questions`} exact={true} component={SmileQuestions} />
        <Route path={`${match.url}/thank-you`} exact={true} component={ThankyouPage} />
        <Route path={`${match.url}/completed`} exact={true} component={FinalPage} />
        <Route path={`${match.url}/competition`} exact={true} component={CompetitionPage} />
        <Route path={`${match.url}/line-success`} exact={true} component={LineSuccessPage} />
        <Route path={`${match.url}/carousal-intro`} exact={true} component={CarousalIntroPage} />
        <Route path={`${match.url}/hello-form`} exact={true} component={HelloFormPage} />
        <Route path={`${match.url}/questions`} exact={true} component={SecondarySmileQuestionsPage} />
        <Route path={`${match.url}/camera-preview`} exact={true} component={CameraPreviewPage} />
        <Route path={`${match.url}/photo-capture`} exact={true} component={PhotoCapturePage} />
        <Route path={`${match.url}/photo-captureD2M`} exact={true} component={PhotoCaptureD2MPage} />
        <Route path={`${match.url}/photo-result`} exact={true} component={PhotoResultPage} />
        <Route path={`${match.url}/acceptance`} exact={true} component={CaseAcceptancePage} />
        <Route path={`${match.url}/book-slot`} exact={true} component={BookAppointmetPage} />
        <Route path={`${match.url}/clinic`} exact={true} component={ClinicSelectPage} />
        <Route path={`${match.url}/residence-error`} exact={true} component={ResidenceErrorPage} />
        <Route path={`${match.url}/disclaimer`} exact={true} component={DisclaimerPage} />
        <Route path={`${match.url}/thanks-screen`} exact={true} component={AIthanksPage} />
        <Route path="*" exact={true} component={URLNotFoundPage} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({
  utmData: state.app.metaData.utmData
});

const mapDispatchToProps = () => ({
});


Routes.propTypes = {
  location: PropTypes.shape().isRequired,
  history: PropTypes.shape().isRequired,
  utmData: PropTypes.shape()
};

Routes.defaultProps = {
  utmData: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Routes));
